exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-acik-riza-metni-js": () => import("./../../../src/pages/acik-riza-metni.js" /* webpackChunkName: "component---src-pages-acik-riza-metni-js" */),
  "component---src-pages-cerez-politikasi-js": () => import("./../../../src/pages/cerez-politikasi.js" /* webpackChunkName: "component---src-pages-cerez-politikasi-js" */),
  "component---src-pages-clarification-text-js": () => import("./../../../src/pages/clarification-text.js" /* webpackChunkName: "component---src-pages-clarification-text-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kisisel-verilerin-islenmesi-hakkinda-aydinlatma-metni-js": () => import("./../../../src/pages/kisisel-verilerin-islenmesi-hakkinda-aydinlatma-metni.js" /* webpackChunkName: "component---src-pages-kisisel-verilerin-islenmesi-hakkinda-aydinlatma-metni-js" */),
  "component---src-pages-kisisel-verilerin-islenmesi-ve-korunmasi-politikasi-js": () => import("./../../../src/pages/kisisel-verilerin-islenmesi-ve-korunmasi-politikasi.js" /* webpackChunkName: "component---src-pages-kisisel-verilerin-islenmesi-ve-korunmasi-politikasi-js" */),
  "component---src-pages-policy-on-processing-and-protection-of-personal-data-js": () => import("./../../../src/pages/policy-on-processing-and-protection-of-personal-data.js" /* webpackChunkName: "component---src-pages-policy-on-processing-and-protection-of-personal-data-js" */),
  "component---src-pages-text-of-explicit-consent-js": () => import("./../../../src/pages/text-of-explicit-consent.js" /* webpackChunkName: "component---src-pages-text-of-explicit-consent-js" */),
  "component---src-templates-category-post-category-post-tsx": () => import("./../../../src/templates/CategoryPost/category-post.tsx" /* webpackChunkName: "component---src-templates-category-post-category-post-tsx" */),
  "component---src-templates-main-category-post-main-category-post-tsx": () => import("./../../../src/templates/MainCategoryPost/main-category-post.tsx" /* webpackChunkName: "component---src-templates-main-category-post-main-category-post-tsx" */)
}

