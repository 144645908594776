module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["tr","en"],"defaultLanguage":"tr","generateDefaultLanguagePage":true,"i18nextOptions":{"keySeparator":".","ignoreJSONStructure":false},"pages":[{"matchPath":"/","languages":["tr","en"]},{"matchPath":"/:lang","languages":["tr","en"]},{"matchPath":"/:lang?/:path","languages":["tr","en"],"getLanguageFromPath":true},{"matchPath":"/:lang?/:mainCategory/:path","languages":["tr","en"],"getLanguageFromPath":true},{"matchPath":"/contact","languages":["tr","en"]},{"matchPath":"/about-us","languages":["tr","en"]},{"matchPath":"/404","languages":["tr","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
